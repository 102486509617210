import { AccountDetails, DetailsTabs } from '../AccountDetails';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GridCellParams,
  GridColDef,
  GridEventListener,
  GridRowModel,
} from '@mui/x-data-grid-premium';
import {
  createAdjustmentDefinition,
  getAdjustments,
  updateAdjustmentDefinition,
} from '@services/api';
import { formatBalance, useTableHeight } from '../../utils';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { AccountsTableFooter } from '../AccountsTableFooter';
import { Actions } from '@models/enums/Actions';
import { AdjustmentOperation } from '../../common/AdjustmentOperation';
import { Box } from '@mui/material';
import { CollapsibleAdjustmentsDetails } from '../CollapsibleAdjustmentsDetails';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { ILink } from '@models/interfaces/entities/ILink';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import useStyles from './styles';

interface IExpandedRow {
  id: string;
  tabName: DetailsTabs;
}

interface IAccountBalance {
  accountId: string;
  accountName: string;
  sourceBalance: number;
  manualAdjustment: number;
  glAdjustment: number;
  fees: number;
  premium: number;
  sectionAdjustment: number;
  totalBalance: number;
}

interface IProps {
  project: IProject;
  accounts: IAccount[];
  type: number;
  balancingType: number;
  category: string;
  balanceSheetTotal: number;
  accountsTotal: number;
  showFees?: boolean;
  showPremiums?: boolean;
  onAdjustmentsChanged: () => void;
  isBalanced: boolean;
}

export const AccountsTable = ({
  project,
  accounts,
  type,
  balancingType,
  category,
  balanceSheetTotal,
  accountsTotal,
  showFees,
  showPremiums,
  onAdjustmentsChanged,
  isBalanced,
}: IProps) => {
  const { classes } = useStyles();

  const [emptyAccountsVisibility, setEmptyAccountsVisibility] = useState(false);
  const [unadjustedAccountsVisibility, setUnadjustedAccountsVisibility] = useState(false);

  const [initialAdjustments, setInitialAdjustments] = useState<IAdjustment[]>([]);
  const [manualAdjustments, setManualAdjustments] = useState<IAdjustment[]>([]);
  const [glAddAdjustments, setGlAddAdjustments] = useState<IAdjustment[]>([]);
  const [glSubtractAdjustments, setGlSubtractAdjustments] = useState<IAdjustment[]>([]);
  const [glMatchAdjustments, setGlMatchAdjustments] = useState<IAdjustment[]>([]);
  const [glBalanceAdjustments, setGlBalanceAdjustments] = useState<IAdjustment[]>([]);
  const [feesAdjustments, setFeesAdjustments] = useState<IAdjustment[]>([]);
  const [premiumAdjustments, setPremiumAdjustments] = useState<IAdjustment[]>([]);
  const { height, onDecreaseTableHeight, onIncreaseTableHeight } = useTableHeight();
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);

  const reloadAdjustments = useCallback(
    (link: ILink, type: number, showFees: boolean, showPremiums: boolean) => {
      if (link) {
        const operations = [
          AdjustmentOperation.Initial,
          AdjustmentOperation.Manual,
          AdjustmentOperation.Add,
          AdjustmentOperation.Subtract,
          AdjustmentOperation.Match,
          AdjustmentOperation.Balance,
        ];
        if (showFees) {
          operations.push(AdjustmentOperation.Fees);
        }
        if (showPremiums) {
          operations.push(AdjustmentOperation.Premium);
        }
        getAdjustmentsRequest(link.href, operations, undefined, undefined, type);
      }
    },
    [],
  );

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setInitialAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Initial),
          );
          setManualAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Manual),
          );
          setGlAddAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Add));
          setGlSubtractAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Subtract),
          );
          setGlMatchAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Match),
          );
          setFeesAdjustments(data.items.filter((x) => x.operation === AdjustmentOperation.Fees));
          setPremiumAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Premium),
          );
          setGlBalanceAdjustments(
            data.items.filter((x) => x.operation === AdjustmentOperation.Balance),
          );
        }
      },
    },
  );

  const { request: updateAdjustmentDefinitionRequest, loading: updateAdjustmentDefinitionLoading } =
    useApi(updateAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          reloadAdjustments(
            project.links[Actions.getAdjustments],
            type,
            showFees || false,
            showPremiums || false,
          );
          onAdjustmentsChanged();
        }
      },
    });

  const { request: createAdjustmentDefinitionRequest, loading: createAdjustmentDefinitionLoading } =
    useApi(createAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          reloadAdjustments(
            project.links[Actions.getAdjustments],
            type,
            showFees || false,
            showPremiums || false,
          );
          onAdjustmentsChanged();
        }
      },
    });

  const onCellEditStart: GridEventListener<'cellEditStart'> = (params, event) => {
    if (event.type === 'dblclick') {
      setTimeout(() => {
        const input = (event.target as HTMLElement).querySelector('input');
        if (input) {
          input.select();
        }
      });
    }
  };

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const isChanged = newRow.manualAdjustment !== oldRow.manualAdjustment;

    if (isChanged) {
      const existingManualAdjustment = manualAdjustments.find(
        (x) => x.accountId === newRow.accountId,
      );

      if (existingManualAdjustment && existingManualAdjustment.links[Actions.updateDefinition]) {
        updateAdjustmentDefinitionRequest(
          existingManualAdjustment.links[Actions.updateDefinition].href,
          {
            source: {
              type: 'manual',
              value: newRow.manualAdjustment,
            },
            targets: [existingManualAdjustment.accountId],
            operation: AdjustmentOperation.Manual,
            baseline: [],
            tierLimits: [],
          },
        );
      } else if (!existingManualAdjustment && project.links[Actions.createAdjustmentDefinition]) {
        createAdjustmentDefinitionRequest(project.links[Actions.createAdjustmentDefinition].href, {
          source: {
            type: 'manual',
            value: newRow.manualAdjustment,
          },
          targets: [newRow.accountId],
          operation: AdjustmentOperation.Manual,
          baseline: [],
          tierLimits: [],
          description: '',
        });
      }
    }

    return newRow;
  };

  const onChangeEmptyAccountsVisibility = (value: boolean) => {
    setEmptyAccountsVisibility(value);
  };

  const onChangeUnadjustedAccountsVisibility = (value: boolean) => {
    setUnadjustedAccountsVisibility(value);
  };

  const columns = useMemo(() => {
    const baseColumns: GridColDef[] = [
      {
        field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
        headerName: 'Detail panel toggle',
        width: 1,
        minWidth: 1,
        maxWidth: 1,
        hideable: false,
        filterable: false,
        sortable: false,
      },
      {
        field: 'accountName',
        headerName: 'Account',
        type: 'string',
        flex: 1,
      },
      {
        field: 'sourceBalance',
        headerName: 'Source Balance',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      },
      {
        field: 'manualAdjustment',
        headerName: 'Manual Adjustment',
        type: 'number',
        flex: 1,
        editable: true,
        renderCell: (params) => formatBalance(params.value || 0),
      },
      {
        field: 'glAdjustment',
        headerName: 'GL Adjustments',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      },
    ];

    if (showFees) {
      baseColumns.push({
        field: 'fees',
        headerName: 'Fees',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      });
    }

    if (showPremiums) {
      baseColumns.push({
        field: 'premium',
        headerName: 'Premiums/Discounts',
        type: 'number',
        flex: 1,
        renderCell: (params) => formatBalance(params.value || 0),
      });
    }

    baseColumns.push({
      field: 'sectionAdjustment',
      headerName: 'Section Adjustments',
      type: 'number',
      flex: 1,
      renderCell: (params) => formatBalance(params.value || 0),
    });

    baseColumns.push({
      field: 'totalBalance',
      headerName: 'Total Balance',
      type: 'number',
      flex: 1,
      renderCell: (params) => formatBalance(params.value || 0),
    });

    return baseColumns;
  }, [showFees, showPremiums]);

  useEffect(() => {
    reloadAdjustments(
      project.links[Actions.getAdjustments],
      type,
      showFees || false,
      showPremiums || false,
    );
  }, [project.links[Actions.getAdjustments]?.href, type, showFees, showPremiums]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter((x) => x.accountType.type === type && x.summaryCode !== 1 && !x.isBalancing),
    [accounts],
  );

  const accountBalances = useMemo<IAccountBalance[]>(() => {
    let accountRecords = filteredAccounts.map((x) => {
      const sourceBalance = initialAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const manualAdjustment = manualAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const glAdjustment = [...glAddAdjustments, ...glSubtractAdjustments, ...glMatchAdjustments]
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const fees = feesAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const premium = premiumAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      const sectionAdjustment = glBalanceAdjustments
        .filter((a) => a.accountId === x.id)
        .reduce((sum, a) => sum + a.amount, 0);
      return {
        accountId: x.id,
        accountName: x.accountName,
        sourceBalance,
        manualAdjustment,
        glAdjustment,
        fees,
        premium,
        sectionAdjustment,
        totalBalance:
          sourceBalance + manualAdjustment + glAdjustment + fees + premium + sectionAdjustment,
      };
    });

    if (!emptyAccountsVisibility) {
      accountRecords = accountRecords.filter((x) => x.totalBalance !== 0);
    }

    if (!unadjustedAccountsVisibility) {
      accountRecords = accountRecords.filter(
        (x) => x.manualAdjustment !== 0 || x.glAdjustment !== 0 || x.fees !== 0 || x.premium !== 0,
      );
    }
    return accountRecords;
  }, [
    filteredAccounts,
    initialAdjustments,
    manualAdjustments,
    glAddAdjustments,
    glSubtractAdjustments,
    glMatchAdjustments,
    glBalanceAdjustments,
    feesAdjustments,
    premiumAdjustments,
    emptyAccountsVisibility,
    unadjustedAccountsVisibility,
  ]);

  const adjustments = useMemo<IAdjustment[]>(
    () => [
      ...initialAdjustments,
      ...manualAdjustments,
      ...glAddAdjustments,
      ...glSubtractAdjustments,
      ...glMatchAdjustments,
      ...glBalanceAdjustments,
      ...feesAdjustments,
      ...premiumAdjustments,
    ],
    [
      initialAdjustments,
      manualAdjustments,
      glAddAdjustments,
      glSubtractAdjustments,
      glMatchAdjustments,
      glBalanceAdjustments,
      feesAdjustments,
      premiumAdjustments,
    ],
  );

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => {
      const expandedRow = expandedRows.find((x) => x.id === row.accountId);
      if (!expandedRow) return null;
      return (
        <AccountDetails
          accountId={row.accountId}
          adjustments={adjustments}
          accounts={accounts}
          project={project}
          tab={expandedRow.tabName}
          hideFeesTab={!showFees}
          hidePremiumsTab={!showPremiums}
        />
      );
    },
    [accounts, adjustments, category, project, expandedRows, showFees, showPremiums],
  );

  const handleCellClick = (params: GridCellParams) => {
    if (
      params.field === 'sourceBalance' ||
      params.field === 'glAdjustment' ||
      params.field === 'fees' ||
      params.field === 'premium' ||
      params.field === 'sectionAdjustment'
    ) {
      let tabName = DetailsTabs.sourceBalance;
      if (params.field === 'glAdjustment') {
        tabName = DetailsTabs.glAdjustments;
      } else if (params.field === 'fees') {
        tabName = DetailsTabs.fees;
      } else if (params.field === 'premium') {
        tabName = DetailsTabs.premiums;
      } else if (params.field === 'sectionAdjustment') {
        tabName = DetailsTabs.sectionAdjustments;
      }
      const isExpanded = expandedRows.some((row) => row.id === params.id);
      setExpandedRows((prev) =>
        isExpanded
          ? prev.filter((row) => row.id !== params.id)
          : [...prev, { id: params.id as string, tabName }],
      );
    }
  };

  const expandedRowIds = useMemo<string[]>(() => expandedRows.map((row) => row.id), [expandedRows]);

  const types = useMemo<number[]>(() => [type], [type]);

  const showLoader = useLoader(
    getAdjustmentsLoading,
    updateAdjustmentDefinitionLoading,
    createAdjustmentDefinitionLoading,
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          style={{ height: accountBalances.length ? height : 'auto' }}
          rows={accountBalances}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'accountName', sort: 'asc' }],
            },
            aggregation: {
              model: {
                sourceBalance: 'sum',
                manualAdjustment: 'sum',
                glAdjustment: 'sum',
                fees: 'sum',
                premium: 'sum',
                sectionAdjustment: 'sum',
                totalBalance: 'sum',
              },
            },
          }}
          slots={{
            footer: () => (
              <AccountsTableFooter
                emptyAccountsVisibility={emptyAccountsVisibility}
                unadjustedAccountsVisibility={unadjustedAccountsVisibility}
                onChangeEmptyAccountsVisibility={onChangeEmptyAccountsVisibility}
                onChangeUnadjustedAccountsVisibility={onChangeUnadjustedAccountsVisibility}
                onMinusClicked={onDecreaseTableHeight}
                onPlusClicked={onIncreaseTableHeight}
              />
            ),
            detailPanelExpandIcon: () => null,
            detailPanelCollapseIcon: () => null,
          }}
          getRowId={(row) => row.accountId}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          onCellClick={handleCellClick}
          detailPanelExpandedRowIds={expandedRowIds}
          rowBuffer={100}
          processRowUpdate={processRowUpdate}
          onCellEditStart={onCellEditStart}
        />
        <CollapsibleAdjustmentsDetails
          adjustments={adjustments}
          balanceAdjustments={glBalanceAdjustments}
          isBalanced={isBalanced}
          accounts={accounts}
          category={category}
          project={project}
          types={types}
          balancingType={balancingType}
          balanceSheetTotal={balanceSheetTotal}
          accountsTotal={accountsTotal}
          withTopPadding
        />
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
