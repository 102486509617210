import { AdjustmentOperation, OperationMap } from '../../common/AdjustmentOperation';
import { Box, Container, Switch, Tooltip, Typography } from '@mui/material';
import { formatBalance, formatPercentage } from '../../utils';
import { getAdjustmentDefinition, getAdjustments } from '@services/api';
import { useApi, useLoader } from '@hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Actions } from '@models/enums/Actions';
import AlertIcon from '@assets/icons/dashboard/alert-triangle-filled.svg';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IAdjustmentDefinition } from '@models/interfaces/entities/IAdjustmentDefinition';
import { IProject } from '@models/interfaces/entities/IProject';
import { Loader } from '@components/Loader';
import clsx from 'clsx';
import useStyles from './styles';

export interface IAdjustmentDetails {
  adjustmentDefinitionId: string;
  sourceSubAccountId?: string | null;
  sourceSubAccountDescription?: string | null;
  operation: string;
  sourceTotal: number;
  matchingBalance: number;
  sourceAdjustment: number;
  groupId?: string | null;
  adjustments: IAdjustment[];
  reverseBalance: boolean;
  groupSources: {
    reverseBalance: boolean;
    sourceSubAccountId?: string | null;
    sourceTotal: number;
  }[];
}

interface IProps {
  details: IAdjustmentDetails;
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
}

export const AdjustmentDetails = ({
  details,
  accounts,
  adjustments,
  category,
  project,
  types,
}: IProps) => {
  const { classes } = useStyles();

  const [adjustmentDefinition, setAdjustmentDefinition] = useState<IAdjustmentDefinition>();
  const [detailsAdjustments, setDetailsAdjustments] = useState<IAdjustment[]>([]);
  const [balanceAdjustments, setBalanceAdjustments] = useState<IAdjustment[]>([]);

  const { request: getAdjustmentDefinitionRequest, loading: getAdjustmentDefinitionLoading } =
    useApi(getAdjustmentDefinition, null, {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          setAdjustmentDefinition(data);
        }
      },
    });

  const { request: getAdjustmentsRequest, loading: getAdjustmentsLoading } = useApi(
    getAdjustments,
    null,
    {
      handleErrors: true,
      onCallback: (data) => {
        if (data) {
          const balanceAdjustments = data.items.filter(
            (x) =>
              x.operation === AdjustmentOperation.Initial ||
              x.operation === AdjustmentOperation.Manual,
          );
          setBalanceAdjustments(balanceAdjustments);

          const detailsAdjustments = data.items.filter(
            (x) => x.adjustmentDefinitionId === details.adjustmentDefinitionId,
          );
          setDetailsAdjustments(detailsAdjustments);
        }
      },
    },
  );

  const getAccountTotalBalance = useCallback(
    (accountId: string, operations: string[], adjustments: IAdjustment[]) => {
      return adjustments
        .filter((x) => x.accountId === accountId && operations.includes(x.operation))
        .reduce((p, c) => p + c.amount, 0);
    },
    [],
  );

  const detailAdjustmentItems = useMemo(() => {
    let balanceOperations: string[] = [];
    if (
      details.operation === AdjustmentOperation.Add ||
      details.operation === AdjustmentOperation.Subtract ||
      details.operation === AdjustmentOperation.Match
    ) {
      balanceOperations = [AdjustmentOperation.Initial, AdjustmentOperation.Manual];
    } else if (details.operation === AdjustmentOperation.Fees) {
      balanceOperations = [
        AdjustmentOperation.Initial,
        AdjustmentOperation.Manual,
        AdjustmentOperation.Add,
        AdjustmentOperation.Subtract,
        AdjustmentOperation.Match,
      ];
    }

    const baselineAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? adjustmentDefinition.baseline.map((x) => x.id)
        : [];

    const baselineOnlyAccountIds =
      details.operation === AdjustmentOperation.Match && adjustmentDefinition
        ? baselineAccountIds.filter(
            (x) => adjustmentDefinition.targets.findIndex((a) => a.id === x) === -1,
          )
        : [];

    const targetAccountsData = detailsAdjustments.map((x) => {
      const account = accounts.find((a) => a.id === x.accountId);
      return {
        account,
        initialBalance:
          details.operation === AdjustmentOperation.Match
            ? getAccountTotalBalance(x.accountId, [AdjustmentOperation.Initial], balanceAdjustments)
            : 0,
        balance: getAccountTotalBalance(x.accountId, balanceOperations, balanceAdjustments),
        adjustment: x.amount,
        adjusted: true,
        isBaseline: baselineAccountIds.includes(x.accountId),
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    const baselineAccountsData = baselineOnlyAccountIds.map((x) => {
      const account = accounts.find((a) => a.id === x);
      return {
        account,
        initialBalance: getAccountTotalBalance(
          x,
          [AdjustmentOperation.Initial],
          balanceAdjustments,
        ),
        balance: 0,
        adjustment: 0,
        adjusted: false,
        isBaseline: true,
        hasCorrectType:
          account &&
          types.includes(account.accountType.type) &&
          !account.isBalancing &&
          account.summaryCode !== 1,
      };
    });

    return [...targetAccountsData, ...baselineAccountsData];
  }, [
    detailsAdjustments,
    accounts,
    types,
    balanceAdjustments,
    details.operation,
    details.adjustmentDefinitionId,
    adjustmentDefinition,
  ]);

  const filteredAccounts = useMemo(
    () =>
      accounts.filter(
        (x) => types.includes(x.accountType.type) && x.summaryCode !== 1 && !x.isBalancing,
      ),
    [accounts, types],
  );

  useEffect(() => {
    if (project.links[Actions.getAdjustmentDefinitions]?.href)
      getAdjustmentDefinitionRequest(
        `${project.links[Actions.getAdjustmentDefinitions].href}/${details.adjustmentDefinitionId}`,
      );
  }, [project.links[Actions.getAdjustmentDefinitions]?.href, details.adjustmentDefinitionId]);

  useEffect(() => {
    if (adjustmentDefinition) {
      const usedAccountIds = Array.from(
        new Set([
          ...adjustmentDefinition.targets.map((x) => x.id),
          ...adjustmentDefinition.baseline.map((x) => x.id),
        ]),
      );

      // detected account from another category
      if (usedAccountIds.some((x) => !filteredAccounts.find((a) => a.id === x))) {
        if (project.links[Actions.getAdjustments]?.href) {
          getAdjustmentsRequest(
            project.links[Actions.getAdjustments].href,
            [AdjustmentOperation.Initial, AdjustmentOperation.Manual, details.operation],
            usedAccountIds,
          );
        }
      } else {
        copyAdjustmentsFromProps();
      }
    }
  }, [filteredAccounts, adjustmentDefinition, project.links[Actions.getAdjustments]?.href]);

  const copyAdjustmentsFromProps = () => {
    setDetailsAdjustments(details.adjustments);
    setBalanceAdjustments(adjustments);
  };

  const detailAdjustmentsTotalAdjustment = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.adjustment, 0),
    [detailAdjustmentItems],
  );

  const detailAdjustmentsTotalAccountsBalance = useMemo(
    () => detailAdjustmentItems.reduce((p, c) => p + c.balance, 0),
    [detailAdjustmentItems],
  );

  const isMatch = useMemo(
    () => details.operation === AdjustmentOperation.Match,
    [details.operation],
  );

  const titleClass = useMemo(() => (isMatch ? classes.colSpan5 : classes.colSpan4), [isMatch]);
  const adjustmentColumnPositionClass = useMemo(
    () => (isMatch ? classes.col6 : classes.col5),
    [isMatch],
  );
  const balanceColumnPositionClass = useMemo(
    () => (isMatch ? classes.col4 : classes.col3),
    [isMatch],
  );
  const spacerClass = useMemo(() => (isMatch ? classes.col6Spacer : classes.col5Spacer), [isMatch]);
  const gridClass = useMemo(() => (isMatch ? classes.col6Grid : classes.col5Grid), [isMatch]);

  const showLoader = useLoader(getAdjustmentDefinitionLoading, getAdjustmentsLoading);

  const sourceTotalSum = useMemo(
    () => (isMatch ? details.groupSources.reduce((sum, source) => sum + source.sourceTotal, 0) : 0),
    [isMatch, details.groupSources],
  );

  const sourceTotalPortion = useMemo(
    () => (isMatch ? details.sourceTotal / sourceTotalSum : 1),
    [isMatch, sourceTotalSum, details.sourceTotal],
  );

  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='md'>
          {details.operation === AdjustmentOperation.Add ||
          details.operation === AdjustmentOperation.Subtract ||
          details.operation === AdjustmentOperation.Fees ||
          details.operation === AdjustmentOperation.Match ? (
            <Box className={clsx([classes.grid, gridClass])}>
              <Box className={clsx([classes.header, titleClass])}>{details.sourceSubAccountId}</Box>
              <Box className={clsx([classes.header, classes.textRight])}>
                {OperationMap[details.operation]}
              </Box>
              <Box className={spacerClass} />

              {isMatch ? (
                <>
                  <Box className={clsx([classes.header, 'alignItemsEnd'])}>
                    Balance Sheet Line Items
                  </Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
                    Reverse Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Portion of Balance
                  </Box>

                  {details.groupSources.map((source) => (
                    <>
                      <Box className={clsx([classes.pl16, classes.col1])}>
                        {source.sourceSubAccountId || 'Unknown'}
                      </Box>
                      <Box className={classes.textRight}>{formatBalance(source.sourceTotal)}</Box>
                      <Box className={clsx([classes.textCenter])}>
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={source.reverseBalance}
                          size='small'
                          disabled
                        />
                      </Box>
                      {details.sourceSubAccountId === source.sourceSubAccountId && (
                        <Box className={classes.textRight}>
                          {formatPercentage(sourceTotalPortion)}
                        </Box>
                      )}
                    </>
                  ))}

                  <Box className={clsx([classes.header, classes.pl16, classes.col1])}>Total</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceTotalSum)}
                  </Box>
                </>
              ) : (
                <>
                  <Box className={clsx([classes.header, 'alignItemsEnd'])}>Line Item Balance</Box>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    {formatBalance(details.sourceTotal)}
                  </Box>
                  <Box className={clsx([classes.header, 'col', 'alignItemsEnd'])}>
                    Reverse Balance <br />
                    <Switch
                      inputProps={{ role: 'switch' }}
                      checked={details.reverseBalance}
                      size='small'
                      disabled
                    />
                  </Box>
                </>
              )}
              <Box className={spacerClass} />

              <Box className={clsx([classes.header, 'alignItemsEnd'])}>
                {isMatch ? 'Match Balance To' : 'Apply Adjustment To'}
              </Box>
              {isMatch && (
                <>
                  <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                    Source Balance
                  </Box>
                  <Box className={clsx([classes.header, classes.textCenter, 'alignItemsEnd'])}>
                    Adjust
                  </Box>
                </>
              )}
              <Box
                className={clsx([
                  classes.header,
                  balanceColumnPositionClass,
                  classes.textRight,
                  'alignItemsEnd',
                ])}
              >
                {isMatch ? 'Manually ' : ''}Adjusted Balance
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Portion of Total
              </Box>
              <Box className={clsx([classes.header, classes.textRight, 'alignItemsEnd'])}>
                Adjustment
              </Box>

              {detailAdjustmentItems.map((x) => (
                <>
                  <Box
                    className={clsx([
                      classes.pl16,
                      classes.col1,
                      (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                    ])}
                  >
                    {x.isBaseline || !isMatch ? (
                      x.hasCorrectType ? (
                        x.account?.accountName || 'Unknown'
                      ) : (
                        <Tooltip
                          title={`This account is not of the correct type (in this case, ${category}). Adjustments defined within a particular account category should only target accounts of the corresponding category.`}
                        >
                          <Box className={clsx([classes.flex, classes.red])}>
                            <img src={AlertIcon} alt='alert' />
                            {x.account?.accountName || 'Unknown'}
                          </Box>
                        </Tooltip>
                      )
                    ) : (
                      <Tooltip
                        title={
                          <Box>
                            This account is not included in the baseline adjustment calculation. To
                            remedy this, either:
                            <ul>
                              <li>Add this account to the baseline adjustment calculation, or</li>
                              <li>Remove this account as an adjustment target.</li>
                            </ul>
                          </Box>
                        }
                      >
                        <Box className={clsx([classes.flex, classes.red])}>
                          <img src={AlertIcon} alt='alert' />
                          {x.account?.accountName || 'Unknown'}
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                  {isMatch && (
                    <>
                      <Box
                        className={clsx([
                          classes.textRight,
                          (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                        ])}
                      >
                        {formatBalance(x.isBaseline ? x.initialBalance : 0)}
                      </Box>
                      <Box className={clsx([classes.textCenter])}>
                        <Switch
                          inputProps={{ role: 'switch' }}
                          checked={x.adjusted}
                          size='small'
                          disabled
                        />
                      </Box>
                    </>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        balanceColumnPositionClass,
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {formatBalance(x.balance)}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box
                      className={clsx([
                        classes.textRight,
                        (!x.hasCorrectType || (!x.isBaseline && isMatch)) && classes.red,
                      ])}
                    >
                      {formatPercentage(
                        detailAdjustmentsTotalAccountsBalance
                          ? x.balance / detailAdjustmentsTotalAccountsBalance
                          : 1 / detailAdjustmentItems.length,
                      )}
                    </Box>
                  )}
                  {x.adjusted && (
                    <Box className={clsx([classes.green, classes.textRight])}>
                      {formatBalance(x.adjustment)}
                    </Box>
                  )}
                </>
              ))}

              <Box className={clsx([classes.pl16, classes.header, classes.col1])}>Total</Box>
              <Box
                className={clsx([classes.header, balanceColumnPositionClass, classes.textRight])}
              >
                {formatBalance(detailAdjustmentsTotalAccountsBalance)}
              </Box>
              <Box className={clsx([classes.header, classes.textRight])}>{formatPercentage(1)}</Box>
              <Box className={spacerClass} />
              {isMatch ? (
                <>
                  <Box className={classes.header}>Total Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(sourceTotalSum - detailAdjustmentsTotalAccountsBalance)}
                  </Box>
                  <Box className={clsx([classes.header, classes.col1])}>Line Item Portion</Box>
                  <Box className={classes.textRight}>{formatPercentage(sourceTotalPortion)}</Box>

                  <Box className={clsx([classes.header, classes.col1])}>Line Item Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(details.sourceAdjustment)}
                  </Box>
                  <Box
                    className={clsx([
                      classes.header,
                      classes.green,
                      adjustmentColumnPositionClass,
                      classes.textRight,
                    ])}
                  >
                    {formatBalance(detailAdjustmentsTotalAdjustment)}
                  </Box>
                </>
              ) : (
                <>
                  <Box className={classes.header}>Total Adjustment</Box>
                  <Box className={clsx([classes.header, classes.textRight])}>
                    {formatBalance(details.sourceAdjustment)}
                  </Box>
                  <Box
                    className={clsx([
                      classes.header,
                      classes.green,
                      adjustmentColumnPositionClass,
                      classes.textRight,
                    ])}
                  >
                    {formatBalance(detailAdjustmentsTotalAdjustment)}
                  </Box>
                </>
              )}
            </Box>
          ) : (
            <Typography variant='h6'>Not implemented yet</Typography>
          )}
        </Container>
      </Box>
      <Loader show={showLoader} />
    </>
  );
};
