import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((t) => ({
  root: {
    padding: 12,
    fontSize: t.typography.body2.fontSize,
    fontWeight: t.typography.body2.fontWeight,
    letterSpacing: t.typography.body2.letterSpacing,
  },
  grid: {
    backgroundColor: t.palette.custom.white[100],
    padding: 12,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: t.palette.divider,
    display: 'grid',
    gap: 8,

    '&>div': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,

      '&.col': {
        flexDirection: 'column',
      },

      '&.alignItemsEnd': {
        alignItems: 'end',
      },
    },
  },
  col5Grid: {
    gridTemplateColumns: '4fr 2fr 2fr 1fr 2fr',
  },
  col6Grid: {
    gridTemplateColumns: '4fr 2fr 1fr 2fr 1fr 2fr',
  },
  header: {
    fontWeight: 600,
  },
  col6: {
    gridColumn: 6,
  },
  col5: {
    gridColumn: 5,
  },
  col4: {
    gridColumn: 4,
  },
  col3: {
    gridColumn: 3,
  },
  col1: {
    gridColumn: 1,
  },
  colSpan5: {
    gridColumnStart: 1,
    gridColumnEnd: 6,
  },
  colSpan4: {
    gridColumnStart: 1,
    gridColumnEnd: 5,
  },
  green: {
    color: t.palette.custom.success[100],
  },
  red: {
    color: t.palette.custom.error[100],
  },
  textRight: {
    justifyContent: 'right',
    textAlign: 'right',
  },
  textCenter: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  pl16: {
    paddingLeft: 16,
  },
  col5Spacer: {
    height: 8,
    gridColumnStart: 1,
    gridColumnEnd: 6,
  },
  col6Spacer: {
    height: 8,
    gridColumnStart: 1,
    gridColumnEnd: 7,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
}));

export default useStyles;
