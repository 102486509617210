import { useCallback, useState } from 'react';

export const formatBalance = (value: number) => {
  const formatted = `$${new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(Math.abs(value))}`;
  return value < 0 ? `(${formatted})` : formatted;
};

export const formatPercentage = (value: number) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  return formatted;
};

export const useTableHeight = (initialHeight = 250, minHeight = 250, maxHeight = 1000) => {
  const [height, setHeight] = useState(initialHeight);

  const onDecreaseTableHeight = useCallback(() => {
    setHeight((prev) => Math.max(prev / 2, minHeight));
  }, [minHeight]);

  const onIncreaseTableHeight = useCallback(() => {
    setHeight((prev) => Math.min(prev * 2, maxHeight));
  }, [maxHeight]);

  return { height, onDecreaseTableHeight, onIncreaseTableHeight };
};
