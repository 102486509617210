import { AdjustmentDetails, IAdjustmentDetails } from '../AdjustmentDetails';
import { AdjustmentOperation, OperationMap } from '../../common/AdjustmentOperation';
import { DataGridPremium, DataGridPremiumProps, GridColDef } from '@mui/x-data-grid-premium';
import { formatBalance, useTableHeight } from '../../utils';
import { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { IAccount } from '@models/interfaces/entities/IAccount';
import { IAdjustment } from '@models/interfaces/entities/IAdjustment';
import { IProject } from '@models/interfaces/entities/IProject';
import { StandardTableFooter } from '../StandardTableFooter';
import useStyles from './styles';

interface IProps {
  adjustments: IAdjustment[];
  accounts: IAccount[];
  category: string;
  project: IProject;
  types: number[];
}

export const AdjustmentsTable = ({ adjustments, accounts, category, project, types }: IProps) => {
  const { classes } = useStyles();

  const { height, onDecreaseTableHeight, onIncreaseTableHeight } = useTableHeight();

  const columns = useMemo(
    () =>
      [
        {
          field: 'sourceSubAccountId',
          headerName: 'Balance Sheet Line Item',
          type: 'string',
          flex: 2,
        },
        {
          field: 'sourceSubAccountDescription',
          headerName: 'Description',
          type: 'string',
          flex: 2,
        },
        {
          field: 'operation',
          headerName: 'Operation',
          type: 'string',
          flex: 1,
          renderCell: (params) => OperationMap[params.value] || params.value,
        },
        {
          field: 'sourceTotal',
          headerName: 'Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) => formatBalance(params.value || 0),
        },
        {
          field: 'matchingBalance',
          headerName: 'Matching Balance',
          type: 'number',
          flex: 2,
          renderCell: (params) =>
            params.value === Number.MIN_VALUE ? 'N/A' : formatBalance(params.value || 0),
        },
        {
          field: 'sourceAdjustment',
          headerName: 'Adjustment',
          type: 'number',
          flex: 2,
          renderCell: (params) => formatBalance(params.value || 0),
        },
      ] as GridColDef<IAdjustmentDetails>[],
    [],
  );

  const adjustmentDetails = useMemo<IAdjustmentDetails[]>(() => {
    const grouped = new Map<string, IAdjustmentDetails>();

    const hasNonNullGroupId = (
      adjustment: typeof adjustments[number],
    ): adjustment is typeof adjustment & { groupId: string } => !!adjustment.groupId;

    const groupTotals = adjustments
      .filter(hasNonNullGroupId)
      .filter((x) => x.operation === AdjustmentOperation.Match)
      .reduce((acc, adjustment) => {
        const { groupId, adjustmentDefinitionId, sourceTotal, sourceAdjustment } = adjustment;

        if (!acc[groupId]) {
          acc[groupId] = { totalSum: 0, adjustmentSum: 0, processedDefinitions: new Set<string>() };
        }

        if (!acc[groupId].processedDefinitions.has(adjustmentDefinitionId)) {
          acc[groupId].totalSum += sourceTotal;
          acc[groupId].adjustmentSum += sourceAdjustment;
          acc[groupId].processedDefinitions.add(adjustmentDefinitionId);
        }

        return acc;
      }, {} as Record<string, { totalSum: number; adjustmentSum: number; processedDefinitions: Set<string> }>);

    adjustments
      .filter((x) =>
        [
          AdjustmentOperation.Add,
          AdjustmentOperation.Subtract,
          AdjustmentOperation.Match,
          AdjustmentOperation.Fees,
        ].includes(x.operation as AdjustmentOperation),
      )
      .forEach((adjustment) => {
        const {
          adjustmentDefinitionId,
          sourceSubAccountId,
          sourceSubAccountDescription,
          operation,
          sourceAdjustment,
          sourceTotal,
          groupId,
          reverseBalance,
        } = adjustment;

        if (!grouped.has(adjustmentDefinitionId)) {
          grouped.set(adjustmentDefinitionId, {
            adjustmentDefinitionId,
            sourceSubAccountId,
            sourceSubAccountDescription,
            operation,
            sourceAdjustment,
            sourceTotal,
            matchingBalance:
              operation === AdjustmentOperation.Match && !!groupId
                ? (groupTotals[groupId]?.totalSum || 0) - (groupTotals[groupId]?.adjustmentSum || 0)
                : Number.MIN_VALUE,
            groupSources: [],
            adjustments: [],
            groupId,
            reverseBalance,
          });
        }

        const group = grouped.get(adjustmentDefinitionId);

        if (group) {
          group.adjustments.push(adjustment);
        }
      });

    const adjustmentDetailsArray = Array.from(grouped.values());

    const groupSourcesByGroupId = adjustmentDetailsArray.reduce((acc, adjDetail) => {
      const { groupId, sourceSubAccountId, sourceTotal, reverseBalance } = adjDetail;
      if (groupId) {
        if (!acc[groupId]) acc[groupId] = [];
        acc[groupId].push({ sourceSubAccountId, sourceTotal, reverseBalance });
      }
      return acc;
    }, {} as Record<string, { sourceSubAccountId?: string | null; sourceTotal: number; reverseBalance: boolean }[]>);

    adjustmentDetailsArray.forEach((adjDetail) => {
      const { groupId } = adjDetail;
      if (groupId) {
        adjDetail.groupSources = groupSourcesByGroupId[groupId] || [];
      }
    });

    return adjustmentDetailsArray;
  }, [adjustments]);

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelContent']>
  >(
    ({ row }) => (
      <AdjustmentDetails
        details={row}
        accounts={accounts}
        adjustments={adjustments}
        category={category}
        project={project}
        types={types}
      />
    ),
    [accounts, adjustments, category, project],
  );

  return (
    <>
      <Box className={classes.root}>
        <DataGridPremium
          style={{ height: adjustmentDetails.length ? height : 'auto' }}
          rows={adjustmentDetails}
          density='compact'
          columns={columns}
          className={classes.table}
          initialState={{
            sorting: {
              sortModel: [{ field: 'sourceSubAccountId', sort: 'asc' }],
            },
          }}
          slots={{
            footer: () => (
              <StandardTableFooter
                onMinusClicked={onDecreaseTableHeight}
                onPlusClicked={onIncreaseTableHeight}
              />
            ),
          }}
          getRowId={(row) => row.adjustmentDefinitionId}
          getDetailPanelHeight={getDetailPanelHeight}
          getDetailPanelContent={getDetailPanelContent}
          rowBuffer={100}
        />
      </Box>
    </>
  );
};
